import React from 'react';
import styled from 'styled-components';

import tillistesBoard from '../../../assets/ourArtisansPage/tillistes-board.png';

const Wrapper = styled.section`
  max-width: 1440px;
  text-align: center;
  margin: 80px auto 162px auto;
  padding: 0 57px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }
`;

const Column = styled.div`
  background-color: ${({ theme }) => theme.colors.green};
  width: 716px;
  max-width: 100%;
  min-height: 588px;
  padding: 50px 86px 0 66px;
  box-sizing: border-box;
  top: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    top: 0;
    min-height: auto;
    padding: 60px 15px;
  }
`;

const Title = styled.h2`
  font-family: Libre Baskerville;
  font-style: italic;
  font-weight: normal;
  font-size: 26px;
  line-height: 31px;
  margin: 0 0 10px 0;
  color: ${({ theme }) => theme.colors.navy};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
  }
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 25px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 0 90px 0;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin-bottom: 40px;
  }
`;

const Img = styled.img`
  position: relative;
  left: -25px;
  width: calc(100% + 25px);
  display: block;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    left: 0;
    width: calc(100% - 30px);
    margin-bottom: 15px;
    padding: 0 15px;
  }
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    flex-direction: column;
  }
`;

const FooterText = styled.p`
  font-family: Libre Baskerville;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 3.5px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 13px 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const FooterSpaceBetweenText = styled.span`
  width: 45px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.black};

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 13px 0;
  }
`;

const Board = () => (
  <Wrapper>
    <Column>
      <Title>Tilliste, n.m ou n.f</Title>
      <Text>
        Couturier, modéliste, styliste.. passionné, bienveillant qui aime partager
        et transmettre son savoir faire. (Profondément) “Humain” aimant le travail bien fait,
        il est curieux de découvrir et d’apprendre de l’autre.
        Doté d’une capacité à se remettre en question et d’être à l’écoute.
        Il sait apprécier les rapports humains et les belles rencontres.
        Le Tilliste aime aussi sa liberté et le fait de ne pas être enfermé dans son atelier.
        Et puis parce qu’il travaille avec passion, il adore voir se dessiner un sourire
        de satisfaction sur le visage de son client !
      </Text>
      <Footer>
        <FooterText>Passionné</FooterText>
        <FooterSpaceBetweenText />
        <FooterText>Curieux</FooterText>
        <FooterSpaceBetweenText />
        <FooterText>Libre</FooterText>
      </Footer>
    </Column>
    <div>
      <Img src={tillistesBoard} alt="Tous nos Tillistes" />
    </div>
  </Wrapper>
);

export default Board;
