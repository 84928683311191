import React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  max-width: 1440px;
  text-align: center;
  margin: 80px auto 90px auto;
  padding: 0 57px;
  box-sizing: border-box;
  text-align: justify;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    margin: 0;
    padding: 0;
  }
`;

const TextContainer = styled.div`
  background: linear-gradient(90deg, ${({ theme }) => theme.colors.lightGrey} 68%, transparent 32%);
  height: 318px;
  padding: 42px;
  box-sizing: border-box;
  position: relative;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    height: auto;
    padding: 0px 15px 60px;
    background: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      height: 53%;
      background-color: ${({ theme }) => theme.colors.lightGrey};
      transform: translate(-50%, -50%);
      z-index: 0;
    }
  }
`;

const Text = styled.p`
  font-family: Libre Baskerville;
  font-size: 26px;
  line-height: 58px;
  color: ${({ theme }) => theme.colors.navy};
  margin: 0;
  position: relative;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.mobileThresholdPixels}) {
    font-size: 18px;
    line-height: 36px;
  }
`;

const TextColor = styled.span`
  color: ${({ theme }) => theme.colors.green};
`;

const Bottom = () => (
  <Container>
    <TextContainer>
      <Text>
        Derrière chaque retouche, derrière chaque transformation upcycling,
        chaque broderie, chaque reprise d’une simple petite jupe à la robe de mariée la plus complexe,
        <TextColor> il y a un Tilliste au talent incroyable</TextColor>
        {' '}
        qui n’a qu’une envie, qu’un but,
        c’est que nos clients soient heureux et satisfaits du travail accompli.
      </Text>
    </TextContainer>
  </Container>
);

export default Bottom;
